import styled from "@emotion/styled";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MuiLink from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import CallingDialog from "../dialog/CallingDialog";
import PrivacyDialog from "../dialog/PrivacyDialog";
import { isPhoneValid } from "../utils/Functions";
import { launchC2C } from "../utils/PostLead";

import bannerHomeMobile from "../assets/images/banner-home-mobile.svg";
import bannerHome from "../assets/images/banner-home.svg";

const useStyles = makeStyles({
  textStack: {
    margin: "0 auto",
    //maxWidth: '460px',
    alignSelf: "center",
    "@media (max-width:2500px)": {
      padding: "40px 80px 40px 45px",
    },
    "@media (min-width:1500px)": {
      "& h1": {
        fontSize: "80px",
      },
      "& h5": {
        fontSize: "20px",
      },
      "& h3": {
        fontSize: "30px",
      },
    },
    "@media (min-width:1200px) and (max-width:1499px)": {
      "& h1": {
        fontSize: "50px",
      },
      "& h5": {
        fontSize: "13px",
      },
      "& h3": {
        fontSize: "18px",
      },
    },

    "@media (max-width:500px)": {
      padding: "20px",
      "& h1, & h2, & h3, & h5": {},
      "& h5": {
        color: "#00404D",
      },
    },
  },
  stack: {
    margin: (props) => props.marginStack,
    "@media (max-width:1500px)": {
      margin: (props) => props.marginStackReduced,
    },
    "@media (max-width:1300px)": {
      margin: "10% 10% auto 10%",
    },
  },
  stackHeader: {
    "@media (max-width:1300px)": {
      marginTop: "7%",
    },
  },
  button: {
    "&:hover": {
      backgroundColor: "var(--colors-primary)",
    },
    "@media (max-width:1300px)": {
      marginBottom: "7%",
    },
  },
  a: {
    textDecoration: "none !important",
  },
});
export const TextFieldWrapper = styled(TextField)({
  "& .MuiInputBase-formControl": {
    padding: "5px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#00404D!important",
  },
  "& .MuiFormLabel-root.MuiInputLabel-root": {
    borderColor: "#00404D!important",
    transform: "translate(14px, 10px) scale(1)",
  },
  "& .MuiFormLabel-root.MuiInputLabel-root.MuiFormLabel-filled": {
    borderColor: "#00404D!important",
    transform: "translate(14px, -7px) scale(0.75)",
  },
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    height: "0.5em",
  },
  fieldset: {
    borderRadius: "5px",
    borderWidth: "2px",
    backgroundColor: "#FFFFFF",
    borderColor: "#00404D",
    opacity: "0.5",
    "& .Mui-focused": {
      borderColor: "#00404D",
    },
  },
});

function HeaderBanner() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checked, setChecked] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isOpenCallingDialog, setIsOpenCallingDialog] = useState(false);
  const [isOpenPrivacyDialog, setIsOpenPrivacyDialog] = useState(false);
  const [visibility, setVisibility] = useState(true);

  const handleClose = (value) => {
    setOpen(false);
  };
  const handleClosePrivacyDialog = () => {
    setIsOpenPrivacyDialog(false);
  };
  const handleCheckboxChange = (event) => {
    setChecked(!checked);
  };
  const handleTextFieldChange = (event) => {
    setPhoneNumber(event.target.value);
  };
  const handleHide = () => {
    setVisibility(!visibility);
  };

  const handleClickOpen = () => {
    setIsOpenCallingDialog(true);
    setTimeout(() => {
      setIsOpenCallingDialog(false);
    }, 3000);
  };
  const handleClicPrivacy = () => {
    setIsOpenPrivacyDialog(true);
  };
  const isNumeric = (str) => /^\d*$/.test(str);
  const [val, setVal] = useState("");

  const onInputChange = (e) => {
    const { value } = e.target;
    if (isNumeric(value)) {
      setVal(value);
    }
  };

  return (
    <>
      <CallingDialog open={isOpenCallingDialog} onClose={handleClose} />
      <PrivacyDialog
        open={isOpenPrivacyDialog}
        onClose={handleClosePrivacyDialog}
      />
      <Grid
        container
        sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
      >
        <Grid item xs={12} md={6}>
          <picture>
            <source media="(max-width: 415px)" srcSet={bannerHomeMobile} />
            <source media="(max-width: 1300px)" srcSet={bannerHome} />
            <img
              width="100%"
              height="100%"
              src={bannerHome}
              alt="banner"
              style={{
                display: "block",
                position: "relative",
                objectFit: "cover",
              }}
            />
          </picture>
        </Grid>
        <Grid item xs={12} md={6} className={classes.textStack}>
          <Stack>
            <Typography variant="h1">
              <b>Repsol</b> luz y gas, <b>al mejor precio</b> del mercado
            </Typography>
            <Typography
              sx={{ display: { xs: "none", sm: "block" } }}
              variant="h3"
            >
              ¡Hasta 300 € de ahorro en tus repostajes!*
            </Typography>
          </Stack>
          <Stack>
            <Grid
              item
              direction="column"
              sm={8}
              justifyContent="space-between"
              alignItems="flex-start"
              sx={{
                marginLeft: "0%",
                marginRight: "5%",
                paddingTop: "1%",
                display: { xs: "none", md: "flex" },
              }}
            >
              <TextFieldWrapper
                value={val}
                onChange={handleTextFieldChange}
                inputProps={{
                  style: {
                    fontSize: 18,
                    color: "#011842",
                    backgroundColor: "white",
                  },
                  maxLength: 9,
                }}
                sx={{ width: "90%", marginTop: "5px" }}
                onChange={(e) => {
                  handleTextFieldChange(e);
                  onInputChange(e);
                }}
                placeholder="Tu teléfono"
                variant="outlined"
              />
              <FormControlLabel
                sx={{ marginTop: "10px", alignItems: "self-start" }}
                control={
                  <Checkbox
                    size="small"
                    onChange={handleCheckboxChange}
                    sx={{
                      padding: "0px 9px",
                      color: "#00404D",
                      "&.Mui-checked": {
                        color: "#00404D",
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    sx={{
                      lineHeight: "16px",
                      fontWeight: "300",
                      fontSize: { sm: "9px", md: "10px", lg: "12px" },
                      color: "#00404D",
                    }}
                    variant="subtitle3"
                  >
                    He leído y acepto la{" "}
                    <MuiLink
                      onClick={() => {
                        handleClicPrivacy();
                      }}
                      style={{
                        color: "#00404D",
                        textDecorationColor: "#00404D",
                      }}
                    >
                      política de privacidad
                    </MuiLink>{" "}
                    y consiento el tratamiento de mis datos personales para
                    todas las finalidades contenidas en la misma
                  </Typography>
                }
              />
              <Button
                onClick={() => {
                  setButtonClicked(true);
                  if (checked && isPhoneValid(phoneNumber)) {
                    launchC2C(phoneNumber);
                    handleClickOpen();
                  }
                }}
                sx={{
                  background: "#EC7A08",
                  color: "#FFFFFF",
                  borderRadius: "5px",
                  marginTop: "15px",
                  marginBottom: "10px",
                  height: "50px",
                  width: "80%",
                  "&:hover": {
                    backgroundColor: "#b15b06",
                    color: "white !important",
                  },
                }}
              >
                <Typography
                  variant="button"
                  component="p"
                  sx={{
                    letterSpacing: 1.9,
                    textTransform: "initial",
                    fontWeight: 600,
                    fontSize: { md: "18px" },
                  }}
                >
                  Llámame gratis
                </Typography>
              </Button>
              {!checked && buttonClicked && (
                <Typography variant="subtitle1" color="red">
                  Debes aceptar la política de privacidad
                </Typography>
              )}
              {checked && buttonClicked && !isPhoneValid(phoneNumber) && (
                <Typography variant="subtitle1" color="red">
                  El número de teléfono no es válido
                </Typography>
              )}
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default HeaderBanner;
