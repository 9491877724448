import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import C2CDialog from '../dialog/C2CDialog';
import { Container, Stack } from '@mui/material';

const useStyles = makeStyles({
  button: {
    '&:hover': {
      backgroundColor: 'var(--colors-primary)',
    },
    '@media (max-width:1200px)': {
      marginBottom: '7%'
    },
  },
  texth2: {
    '@media (max-width:600px)': {
      fontSize: '1.125rem!important'
    },
  },
  textp: {
    '& ul':{
      paddingInlineStart:'20px',
      display:'flex',
      flexDirection:'column',
      gap:'20px'
    },
    '@media (max-width:600px)': {
      fontSize: '1rem!important'
    },
  }
});

function ComplexCardPhoto({ title, ctaText, description, imgSrc }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <C2CDialog
        open={open}
        onClose={handleClose}
      />
      <Card sx={{
        background: '0% 0% no-repeat padding-box',
        boxShadow: '0px 0px 15px #0000000D;',
        borderRadius: '20px',
        alignItems: 'flex-start',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        maxWidth:'400px',
        margin:'0 auto',
      }}>
        <CardContent sx={{
          padding: '0px',
          display: 'flex',
          flex: '1 0 auto',
          alignItems: 'flex-start',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
        }}>
          <picture>
            <img width="100%" height="auto" src={require(`../assets/images/${imgSrc}`)} alt="banner" />
          </picture>
          <Typography
            sx={{ lineHeight: '1.3', textAlign: 'left', marginBottom: '10px', paddingTop:'1rem' }}
            style={{ fontSize: '1.375rem', color: 'var(--colors-text-normal)', fontFamily: 'Roboto,Arial', fontWeight: '700' }}
            variant="h2" className={classes.texth2} paddingLeft="1rem"
            dangerouslySetInnerHTML={{ __html: title }} />
          <Typography
            sx={{ lineHeight: '1.3', textAlign: 'left', marginBottom: '10px' }}
            style={{ color: 'var(--colors-text-dim)', fontSize: '1.125rem' ,paddingLeft:"1rem", paddingRight:"1rem", fontWeight:300 }}
            variant="body2" className={classes.textp}
            dangerouslySetInnerHTML={{ __html: description }} />

          <Container sx={{textAlign:'center'}}>
            <Button className={classes.button}
              onClick={() => {
                handleClickOpen();
              }}
              sx={{
                background: 'var(--colors-bg-button)',
                boxShadow: '0px 3px 6px #00000029',
                borderRadius: '5px',
                marginTop: 'auto',
                height: '50px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <Typography sx={{ lineHeight: '1.4rem', textTransform: 'initial', fontSize:{md:'18px'}, fontWeight:600}} color="white" variant="subtitle1">{ctaText}</Typography>
            </Button>
          </Container>
        </CardContent>
      </Card>
    </>
  );
}

export default ComplexCardPhoto;
