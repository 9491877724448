import { CardHeader, CardMedia } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles({
  media: {
    //width: '80%',
    //height: 'auto',
    display: "block",
    marginTop: (props) => props.marginTop,
    marginLeft: (props) => props.marginLeft,
    width: (props) => props.imageWidth,
    height: (props) => props.imageHeight,
    objectFit: "contain",
    "@media (max-width:600px)": {
      marginLeft: (props) => props.marginLeftMobile,
      marginRight: (props) => props.marginRightMobile,
      marginTop: "10%",
      width: (props) => props.imageWidthMobile,
      height: (props) => props.imageHeightMobile,
    },
  },
  text: {
    marginLeft: (props) => props.marginLeftText,
    marginTop: (props) => props.marginTopText,
    fontWeight: "600",
    lineHeight: "1.3",
    color: "#011842",
    "@media (max-width:600px)": {
      marginLeft: (props) => props.marginLeftTextMobile,
    },
  },
});

function RoundedPlainCard({ image, title, props }) {
  const classes = useStyles(props);

  return (
    <Card
      sx={{
        width: { xs: "100%", sm: 260, xl: 320 },
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        borderRadius: "5px",
        height: (props) => props.height,
        display: "block",
        justifyContent: "center",
        boxShadow: "0px 3px 6px #00000029",
      }}
    >
      <CardHeader
        avatar={
          <CardMedia className={classes.media} component="img" image={image} />
        }
        title={
          <Box className={classes.text}>
            <Typography className={classes.text} variant="body2">
              {title}
            </Typography>
          </Box>
        }
      />
    </Card>
  );
}

export default RoundedPlainCard;
