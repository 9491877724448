import './App.css';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import GalanoGrotesqueLight from './assets/fonts/galano-grotesque/GalanoGrotesque-Light.woff2';
import GalanoGrotesqueBold from './assets/fonts/galano-grotesque/GalanoGrotesque-Bold.woff2';
import RepsolRegular from './assets/fonts/repsol/Repsol-Regular.woff';
import Home from './pages/Home';
import Cookies from './pages/Cookies';
import Privacy from './pages/Privacy';
import Legal from './pages/Legal';
import { Routes, Route } from "react-router-dom";

let theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Arial',
    color: '#00404D',
    h1: {
      fontSize: '79px',
      fontFamily: 'Repsol, Arial',
      color:"#011842", 
    },
    h2: {
      color: 'var(--colors-primary)',
      fontSize: '60px',
      fontFamily: 'Repsol, Arial',
    },
    h3: {
      fontSize: '45px',
      fontFamily: 'Repsol, Arial',
      color:"#011842", 
    },
    h4: {
      color:"#011842", 
      fontSize: '28px',
      fontFamily: 'Repsol, Arial',
    },
    h5: {
      fontSize: '25px',
      fontFamily: 'Repsol, Arial',
    },
    subtitle1: {
    },
    subtitle2: {
    },
    body1: {
    },
    body2: {
    },
    body3: {
    },
    body4: {
    },
    body5: {
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Galano Grotesque';
          font-style: normal;
          font-display: swap;
          src: local('Galano Grotesque'), local('Galano Grotesque'), url(${GalanoGrotesqueLight}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Galano Grotesque Bold';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Galano Grotesque Bold'), local('Galano Grotesque Bold'), url(${GalanoGrotesqueBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }

        @font-face {
          font-family: 'Repsol';
          font-style: normal;
          font-display: swap;
          src: local('Repsol'), local('Galano Grotesque'), url(${RepsolRegular}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="cookies" element={<Cookies />} />
        <Route path="privacidad" element={<Privacy />} />
        <Route path="legal" element={<Legal />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
