
import { landingCommander } from '../../node_modules/@bysidecar/landing_commander/dist/main';
import { useEffect, useState } from 'react';


export function getDDI() {
    let landcom = new landingCommander();
    const paramsUrl = landcom.getParametersURL();
  
    let gclid = paramsUrl.gclid;
    let msclkid = paramsUrl.msclkid;
    let utm_source = paramsUrl.utm_source;
  
    // utm_source is the less important param
    if (gclid === null && msclkid === null && (utm_source === '' || utm_source === null)) {
      utm_source = 'default';
    } else {
      if (gclid !== null || msclkid !== null) {
        utm_source = null;
      } else {
          utm_source = paramsUrl.utm_source;
      }
    }
    // those params are iOS privacy params. If any exist we must show the google DDI
    if (paramsUrl.gbraid || paramsUrl.wbraid) {
      gclid = 'google';
      utm_source = null;
    }
    // campaign param has priority over other
    if (paramsUrl.campaign) {
      utm_source = paramsUrl.campaign;
      gclid = null;
      msclkid = null
    }
  
    const options = {
      utm_source,
      gclid,
      microsite: 'contratarelectricidad.es',
      msclkid: msclkid,
    };
  
    return landcom.getDDI(options)
      .then((response) => {
        return response.data.TELEFONO;
      })
      .catch(e => this.throwError(e));
}
export const useDDI = () => {  
  const [ddi, setDDI] = useState(919900022);

  useEffect(() => {
    getDDI().then((result) => {
      setDDI(result);
    });
  }, []);

  return ddi;
};