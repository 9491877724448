import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinkMui from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ColoredCheckbox from "../checkbox/ColoredCheckbox";
import CallingDialog from "../dialog/CallingDialog";
import PrivacyDialog from "../dialog/PrivacyDialog";
import {
  isPhoneValid,
  isResponsive,
  isResponsiveTablet,
} from "../utils/Functions";
import { launchC2C } from "../utils/PostLead";

const useStyles = makeStyles((theme) => ({
  form: {
    top: "100px",
    width: "360px",
    right: "-360px",
    position: "fixed",
    zIndex: 1101,
    padding: "0",
    background: "#FF8200",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "0",
    borderBottomRightRadius: "0",
    borderBottomLeftRadius: "5px",
    transition: "transform .2s ease",
    boxShadow: "0px 1px 50px #00000029",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
        border: "1px solid",
      },
      "&:hover fieldset": {
        borderColor: "white",
        border: "1px solid",
      },
    },
    "& .MuiInputLabel-root.Mui-focused": {
      backgroundColor: "var(--colors-primary)",
    },
    "&.open": {
      transform: "translateX(-360px)",
      overflowY: "overlay",
      height: "85vh",
      width: "20.5vw",
    },
    [theme.breakpoints.down("md")]: {
      top: "145px",
      bottom: 0,
      left: 0,
      right: 0,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      transform: "translateY(100vh)",
      "&.open": {
        transform: "translateY(0)",
        width: "100%",
        height: "100vh",
        overflowY: "hidden",
      },
      "@media (max-width:900px)": {
        backgroundColor: "var(--colors-primary)",
        top: "0px",
      },
    },
  },
  animPhoneRinging: {
    width: "120px",
    height: "120px",
    margin: "-40px",
    "& path": {
      fill: "#FFFFFF !important",
    },
    [theme.breakpoints.down("md")]: {
      margin: "-40px -30px -40px -40px",
    },
  },
  formBox: {
    [theme.breakpoints.down("md")]: {
      padding: "0 24px 24px 24px",
    },
  },
  submit: {
    textAlign: "center",
    borderRadius: "5px",
    backgroundColor: "var(--colors-primary-hover)",
    "&:hover": {
      backgroundColor: "#011842",
    },
  },
  sticky: {
    zIndex: 1100,
    position: "fixed",
    top: "50%",
    right: 0,
    height: "110px",
    width: "140px",
    padding: "15px",
    background: "#FF8200 0% 0% no-repeat padding-box",

    borderTopLeftRadius: "5px",
    borderTopRightRadius: "0",
    borderBottomRightRadius: "0",
    borderBottomLeftRadius: "5px",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "&:hover": {
      background: "#1388a0",
    },

    [theme.breakpoints.down("md")]: {
      top: "auto",
      bottom: 0,
      width: "100%",
      height: "auto",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
      borderBottomRightRadius: "0",
      borderBottomLeftRadius: "0",
    },
  },
  txtPop1: {
    fontSize: "1.375vw",
    color: "white",
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
    },
  },
  txtPop2: {
    fontSize: "2vw",
    color: "white",
    [theme.breakpoints.down("md")]: {
      fontSize: "35px",
    },
  },
  txtPop3: {
    fontSize: "5.2vw",
    color: "white",
    [theme.breakpoints.down("md")]: {
      fontSize: "98px",
    },
  },
  logoSticky: {
    width: "17vw",
    [theme.breakpoints.down("md")]: {
      width: "270px",
    },
  },
  formBox1: {
    height: "20vh",
    [theme.breakpoints.down("md")]: {
      maxHeight: "0vh",
    },
  },
}));

const StickyFormMobile = () => {
  const [width] = React.useState(window.innerWidth);
  const initialCanvas =
    !isResponsive(width) && !isResponsiveTablet(width) ? false : false;
  const classes = useStyles();

  const { search } = useLocation();
  const [offcanvas, setOffcanvas] = useState(initialCanvas);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checked, setChecked] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isOpenCallingDialog, setIsOpenCallingDialog] = useState(false);
  const [isOpenPrivacyDialog, setIsOpenPrivacyDialog] = useState(false);

  const handleClose = () => {
    setChecked(false);
    clearPhone();
    setButtonClicked(false);
  };

  const handleCheckboxChange = (event) => {
    setChecked(!checked);
  };

  const handleTextFieldChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleClickOpen = () => {
    setIsOpenCallingDialog(true);
  };

  const handleCloseCallingDialog = () => {
    setIsOpenCallingDialog(false);
    setButtonClicked(false);
    handleClose();
  };
  const handleClosePrivacyDialog = () => {
    setIsOpenPrivacyDialog(false);
  };
  const handleClicPrivacy = () => {
    setIsOpenPrivacyDialog(true);
  };

  const clearPhone = () => {
    setPhoneNumber("");
    setChecked(true);
    setButtonClicked(false);
  };

  return (
    <>
      <CallingDialog
        open={isOpenCallingDialog}
        onClose={handleCloseCallingDialog}
      />
      <PrivacyDialog
        open={isOpenPrivacyDialog}
        onClose={handleClosePrivacyDialog}
      />
      <Button
        className={classes.sticky}
        disableRipple={true}
        onClick={() => {
          setOffcanvas(() => {
            return !offcanvas;
          });
        }}
        sx={{
          flexDirection: { sx: "row", sm: "column" },
          display: { xs: "block", md: "none!important" },
        }}
      >
        <Typography
          color="white"
          variant="subtitle2"
          style={{
            textTransform: "initial",
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Llámame gratis
        </Typography>
      </Button>
      <Box
        className={{ [classes.form]: true, open: offcanvas }}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 15,
            right: 15,
            color: "white",
          }}
          onClick={() => setOffcanvas(false)}
        >
          <CloseIcon sx={{ fontSize: "2.5rem" }} />
        </IconButton>
        <Grid container xs={12} className={classes.formBox1}></Grid>
        <Grid className={classes.formBox} padding="0 24px 2.5rem 24px">
          <Typography variant="h3">
            <span className={classes.txtPop1} style={{ display: "block" }}>
              ¿Quieres contratar?
            </span>
            <span className={classes.txtPop2} style={{ display: "block" }}>
              Te llamamos ahora
            </span>
            <span
              className={classes.txtPop3}
              style={{
                display: "block",
                lineHeight: "0.8em",
                textTransform: "initial",
              }}
            >
              Gratis
            </span>
          </Typography>

          <Typography style={{ fontSize: "1rem", color: "white" }}>
            Nuestro equipo de asesores te atenderá de lunes a viernes de 09:00 a
            21:00
          </Typography>
          <TextField
            inputProps={{ style: { fontSize: 14, color: "white" } }}
            value={phoneNumber}
            onChange={handleTextFieldChange}
            sx={{ width: "100%", margin: "10px 0" }}
            label={
              <Typography
                variant="body2"
                color="white"
                sx={{ color: "white!important" }}
              >
                Tu teléfono
              </Typography>
            }
            variant="outlined"
          />
          <FormControlLabel
            sx={{
              alignItems: "start",
              marginTop: "4px",
              marginBottom: "4px",
              fontSize: "12px",
              color: "var(--colors-text-dim)",
            }}
            control={<ColoredCheckbox onChange={handleCheckboxChange} />}
            label={
              <Typography
                variant="subtitle2"
                sx={{ fontSize: "0.7rem", color: "white" }}
              >
                He leído y acepto la{" "}
                <LinkMui
                  underline="none"
                  onClick={() => {
                    handleClicPrivacy();
                  }}
                  sx={{ textDecoration: "none", color: "white" }}
                >
                  política de privacidad
                </LinkMui>{" "}
                y consiento el tratamiento de mis datos personales para todas
                las finalidades contenidas en la misma.
              </Typography>
            }
          />
          {!checked && buttonClicked && (
            <Typography variant="subtitle2" color="red">
              Debes aceptar la política de privacidad
            </Typography>
          )}
          {checked && buttonClicked && !isPhoneValid(phoneNumber) && (
            <Typography variant="subtitle2" color="red">
              El número de teléfono no es válido
            </Typography>
          )}
          <Button
            className={classes.submit}
            onClick={() => {
              setButtonClicked(true);
              if (checked && isPhoneValid(phoneNumber)) {
                launchC2C(phoneNumber);
                handleClickOpen();
              }
            }}
            sx={{
              background: "#011842 0% 0% no-repeat padding-box",
              borderRadius: "10px",
              marginTop: "1.2rem",
              height: "40px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              color="white"
              variant="body3"
              sx={{ textTransform: "initial" }}
            >
              Llámame gratis
            </Typography>
          </Button>
        </Grid>
      </Box>
    </>
  );
};

export default StickyFormMobile;
