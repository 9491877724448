import * as React from 'react';
import Grid from '@mui/material/Grid';
import RoundedPlainCard from '../card/luz/RoundedPlainCard';
import Heading from '../heading/Heading';
import {makeStyles} from "@mui/styles";
const useStyles = makeStyles({
  cardsStack: {
    alignSelf: 'center',
  },
  cardsImages: {
    height: '50px', width: '50px'
  },
});
const RoundedPlainCardsBlock = ({infoPlainCards}) => {
  const classes = useStyles();
  return (
      <Grid item xs={12} sm={12} justifyContent={"center"} style={{display: 'flex'}} sx={{padding: {xs: '0 20px', sm:'0'}, marginLeft: '4px', flexDirection:'column', alignItems:'flex-start'}}>
        <div className={classes.cardsStack}>
        <Grid container spacing={{ xs: 2, lg: 2 }} columns={{ xs: 1, sm: 4, md: 4 }}
          justifyContent="flex-start"
          alignItems="center">
          {infoPlainCards.info.map((info, i) =>
            <Grid key= {i} item sx={{ width: {xs: '100%', sm: '290px', xl:'352px'}, paddingLeft:{xs:'0px!important', sm:'16px!important'}}}>
              <RoundedPlainCard
                image={require(`../assets/images/${info.image}`)}
                title={info.title}
                props={{ imageHeight: '60px', imageWidth: '60px',imageWidthMobile:'50px', imageHeightMobile:'50px', marginTop: '0px', marginLeft: '0', marginLeftMobile: '0'}}/>
            </Grid>  
          )}
        </Grid>
        </div>
      </Grid>
  );
}

export default RoundedPlainCardsBlock;
