import { Container, Stack, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import BannerImage from "../banner/BannerImage";
import HeaderBanner from "../banner/HeaderBanner";
import StickyC2CBottom from "../button/StickyC2CBottom";
import StickyFormMobile from "../button/StickyFormMobile";
import ComplexCardsBannerFullWidth from "../card/ComplexCardsBannerFullWidth";
import LeftInfoBannerC2C from "../card/LeftInfoBannerC2C";
import StickyContainer from "../container/StickyContainer";
import Footer from "../footer/Footer";
import HeaderMenuDDI from "../header/HeaderMenuDDI";
import QuestionsHome from "../questions/QuestionsHome";
import { useDDI } from "../utils/GetDDI";

import bannerInfo from "../assets/images/repsol_info.svg";
import bannerInfoMobile from "../assets/images/repsol_info_movil.svg";

function Home() {
  const ddi = useDDI();
  const { search } = useLocation();
  return (
    <>
      <Stack>
        <StickyFormMobile />
        <StickyContainer>
          <Container maxWidth="false" disableGutters>
            <HeaderMenuDDI link={`/${search}`} ddi={ddi} />
          </Container>
        </StickyContainer>
        <Container maxWidth="false" disableGutters>
          <HeaderBanner />
        </Container>
        {/*¡  <Container maxWidth="false" disableGutters sx={{margin: {xs: '10px' 0px, sm:'60px 0px'}}}>
          <CardsImages />
        </Container>*/}
        <Container
          maxWidth="false"
          disableGutters
          sx={{
            margin: { xs: "10px 0px", sm: "60px 0px" },
            marginTop: { xs: "20px", sm: "120px" },
          }}
        >
          <ComplexCardsBannerFullWidth />
        </Container>
        <Container
          sx={{ margin: { xs: "10px 0px", sm: "20px 0px" } }}
          maxWidth="false"
          disableGutters
        >
          <BannerImage
            imageDesktop={bannerInfo}
            imageMobile={bannerInfoMobile}
          />
        </Container>
        <Container
          sx={{ margin: { xs: "10px 0px", sm: "60px 0px" } }}
          maxWidth="false"
          disableGutters
        >
          <LeftInfoBannerC2C ddi={ddi} />
        </Container>
        {/*}
        <Container
          sx={{ margin: { xs: "10px 0px", sm: "60px 0px" } }}
          maxWidth="false"
          disableGutters
        >
          <RightInfoBanner />
        </Container>*/}
        <Container
          maxWidth="false"
          disableGutters
          style={{ marginBottom: "60px" }}
          sx={{ padding: { xs: "0 20px", md: "0 5rem" } }}
        >
          <Typography
            sx={{
              marginTop: "40px",
              marginBottom: "30px",
              marginRight: { sm: "10%" },
              textAlign: "left",
            }}
            variant="h3"
            align="center"
          >
            Resolvemos tus dudas
          </Typography>
          <QuestionsHome />
        </Container>
      </Stack>
      <StickyC2CBottom />
      <Stack sx={{ marginBottom: { md: "120px" } }}>
        <Footer />
      </Stack>
    </>
  );
}

export default Home;
