import React from 'react';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import C2CDialog from '../dialog/C2CDialog';
import { dial, isResponsive } from '../utils/Functions';
import Button from "@mui/material/Button";

const useStyles = makeStyles({
  button: {
    display:'block',
    marginRight: '0px',
    textAlign: 'center',
    backgroundColor: 'var(--colors-bg-button)',
    '&:hover': {
      backgroundColor: 'var(--colors-bg-button-hover)',
    },
    '@media (max-width:1200px)': {
      display: 'none',
    },
  }
});

const C2CButton = ({buttonText, ddi}) => {
  const classes = useStyles();
	const [width] = React.useState(window.innerWidth);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };


  return (
    <>
      <C2CDialog
        open={open}
        onClose={handleClose}
      />
      <Button className={classes.button}
        onClick={() => {
          if (isResponsive(width)) {
            dial(ddi);
          } else {
            handleClickOpen();
          }
        }}
        sx={{
          background: '#FF6200 0% 0% no-repeat padding-box',
          borderRadius: '5px',
          marginTop: '10px',
          height: '45px',
          width: '100%',
          minWidth:'220px',
          paddingLeft: '35px',
          paddingRight: '35px',
        }}>
        <Stack sx={{ marginTop: '0px' }} spacing={0}>
          <Typography color="white" variant="body5" sx={{fontSize: '18px', fontWeight:600, fontSize:{md:'18px'}, textTransform:'initial' }}>{buttonText}</Typography>
        </Stack>
      </Button>
    </>
  );
}

export default C2CButton;
