import Box from "@mui/material/Box";
import React, { useState } from "react";
import C2CDialog from "../dialog/C2CDialog";

function BannerImage({ imageDesktop, imageMobile }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <>
      <C2CDialog open={open} onClose={handleClose} />
      <Box sx={{ position: "relative", marginTop: "0px" }}>
        <picture>
          <source media="(max-width: 500px)" srcSet={imageMobile} />
          <img
            width="100%"
            height="auto"
            src={imageDesktop}
            alt="banner"
            onClick={() => handleClickOpen()}
          />
        </picture>
      </Box>
    </>
  );
}

export default BannerImage;
