import React from 'react';
import Typography from '@mui/material/Typography';

function Heading({text}) {
  return(
    <Typography sx={{marginTop: '60px', marginBottom: '10px', marginLeft: {xs:'4px', sm:'5%'}, marginRight: {xs:'4px', sm:'10%'}, textAlign: { xs: 'left', sm: 'left', md: 'left'}, color:'var(--colors-primary)' }} variant="h3" align="center" color="#FF0000">{text}</Typography>
  )
}

export default Heading;
