import { landingCommander } from '@bysidecar/landing_commander/dist/main';
import Cookies from 'js-cookie';
import { md5 } from './md5.js';

export const launchC2C = (phoneNumber) => {
      postLead(undefined, phoneNumber);
}

const postLead = (ip, phoneNumber) => {
  const urlEndPoint = "https://leads.bysidecar.me/lead/store/";

  landingCommander.makePostRequest(getLeadPayload(ip, phoneNumber), urlEndPoint)
    .then((result) => {
      pushToDataLayer(phoneNumber, result.message);
    })
    .catch(e => {
      pushToDataLayer(phoneNumber, undefined);
    })
}

const getLeadPayload = (ip, phoneNumber) => {
  let landcom = new landingCommander();
  const paramsUrl = landcom.getParametersURL();

  return {
    sou_id: 91,
    lea_type: 1,
    url: window.location.href,
    ip: ip,
    utm_source: paramsUrl.utm_source,
    sub_source: paramsUrl.sub_source,
    gclid: paramsUrl.gclid,
    domain: window.location.hostname,
    phone: phoneNumber,
    mail: '',
    name: '',
    dni: '',
    ga_client_id: Cookies.get('_ga'),
    smartcenter: true,
    observations: ''
  };
}

const pushToDataLayer = (phoneNumber, leadId) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event',
    eventCategory: 'cmb',
    eventAction: 'click',
    eventLabel: 'home',
    phoneHash: md5(phoneNumber),
    idLead: leadId
  });
}

export const pushToDataLayerDDI = (ddi) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event',
    eventCategory: 'ddi',
    eventAction: 'click',
    eventLabel: ddi,
  });
}