import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import BysidecarLogo from "../assets/images/bysidecar_logo_header3.svg";
import BysidecarLogoMobile from "../assets/images/bysidecarLogoMobile.svg";
import RepsolLogo from "../assets/images/repsol-logo.svg";

const useStyles = makeStyles({
  leftContainer: {
    height: "100%",
    background: "var(--colors-bg-button)",
  },
  leftStack: {
    marginTop: "0",
    marginRight: "20px",
    "@media (max-width:600px)": {
      marginTop: "0",
      marginRight: "0",
      textAlign: "center",
    },
  },
  grid: {
    boxShadow: "0px 3px 6px #00000029",
    height: "100px",
    flexGrow: 1,
    "@media (max-width:600px)": {
      height: "auto",
      paddingBottom: "120px",
    },
  },
  bysidecarFooterLogo: {
    width: "50%",
    "@media (max-width:600px)": {
      width: "80px",
    },
  },
  repsolFooterLogo: {
    paddingLeft: "20px",
    "@media (max-width:600px)": {
      paddingLeft: "0",
    },
  },
});

function Footer() {
  const classes = useStyles();

  const { search } = useLocation();

  return (
    <Grid
      className={classes.grid}
      sx={{ flexDirection: { xs: "column", md: "row" }, marginBottom: "25px" }}
      container
      justifyContent="flex-start"
      alignItems={{ xs: "justify", sm: "center" }}
      spacing={1}
      maxWidth="false"
    >
      <Grid
        container
        xs={12}
        sm={2}
        className={classes.leftContainer}
        justifyContent={{ xs: "center", sm: "end" }}
        alignItems={{ xs: "justify", sm: "center" }}
        padding="15px 0"
      >
        <Stack
          spacing={-1.3}
          alignItems={{ xs: "justify", sm: "flex-end" }}
          sx={{ display: { xs: "none", md: "block" } }}
          className={classes.leftStack}
        >
          <Link to={`/${search}`}>
            <img src={BysidecarLogo} alt="logo" />
          </Link>
          {/* <Typography color="white" variant="subtitle2">Colaborador oficial de Electricidad y Gas</Typography> */}
        </Stack>
        <Stack
          spacing={-1.3}
          alignItems={{ xs: "justify", sm: "flex-end" }}
          sx={{ display: { xs: "block", md: "none" } }}
          className={classes.leftStack}
        >
          <Link to={`/${search}`}>
            <img
              src={BysidecarLogoMobile}
              alt="logo"
              className={classes.bysidecarFooterLogo}
            />
          </Link>
          {/* <Typography color="white" variant="subtitle2">Colaborador oficial de Electricidad y Gas</Typography> */}
        </Stack>
      </Grid>
      <Grid
        container
        xs={12}
        sm={3}
        sx={{ justifyContent: { xs: "center", sm: "start" } }}
        className={classes.repsolFooterLogo}
        padding="15px 0"
      >
        <Link to={`/${search}`}>
          <img src={RepsolLogo} alt="logo" />
        </Link>
      </Grid>
      <Grid item xs={12} sm={7} sx={{ fontWeight: 300 }}>
        <Grid
          container
          spacing={{ sm: 4 }}
          justifyContent="flex-end"
          sx={{
            textAlign: { xs: "center", sm: "left" },
            flexDirection: { xs: "column", sm: "row" },
            paddingRight: { xs: "0", sm: "20px" },
          }}
        >
          <Grid
            item
            xs="auto"
            sx={{
              order: { xs: "2", sm: "0" },
              marginTop: { xs: "20px", sm: "20px", md: "0px" },
            }}
          >
            <Link to={`/cookies${search}`} style={{ textDecoration: "none" }}>
              <Typography
                color="#464646"
                variant="subtitle2"
                sx={{ fontWeight: 300 }}
              >
                Política de cookies
              </Typography>
            </Link>
          </Grid>
          <Grid
            item
            xs="auto"
            sx={{
              order: { xs: "3", sm: "0" },
              marginTop: { xs: "20px", sm: "0px" },
            }}
          >
            <Link
              to={`/privacidad${search}`}
              style={{ textDecoration: "none" }}
            >
              <Typography
                color="#464646"
                variant="subtitle2"
                sx={{ fontWeight: 300 }}
              >
                Política de privacidad
              </Typography>
            </Link>
          </Grid>
          <Grid
            item
            xs="auto"
            sx={{
              order: { xs: "1", sm: "0" },
              marginTop: { xs: "20px", sm: "0px" },
            }}
          >
            <Link to={`/legal${search}`} style={{ textDecoration: "none" }}>
              <Typography
                color="#464646"
                variant="subtitle2"
                sx={{ fontWeight: 300 }}
              >
                Información legal
              </Typography>
            </Link>
          </Grid>
          <Grid
            item
            sx={{
              order: { xs: "10", sm: "0" },
              marginTop: { xs: "42px", sm: "0" },
            }}
          >
            <Typography
              color="#464646"
              variant="subtitle2"
              sx={{ fontWeight: 300 }}
            >
              © 2024 Contratarelectricidad.es All Rights Reserved.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Footer;
