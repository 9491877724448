import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import LinkMui from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ColoredCheckbox from "../checkbox/ColoredCheckbox";
import { isPhoneValid } from "../utils/Functions";
import { launchC2C } from "../utils/PostLead";
import CallingDialog from "./CallingDialog";
import PrivacyDialog from "./PrivacyDialog";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiPaper-root": {
      backgroundColor: "#011842",
    },
  },
  button: {
    marginTop: "1rem",
    marginBottom: "4rem",
    width: "20rem",
    display: "inline",
    "&:hover": {
      backgroundColor: "#F39D0A",
    },
    "@media (max-width:1200px)": {
      marginBottom: "7%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "13rem",
    },
  },
  txtPop1: {
    fontSize: "26px",
    color: "white",
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
    },
  },
  txtPop2: {
    fontSize: "2.1875vw",
    color: "white",
    [theme.breakpoints.down("md")]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
    },
  },
  txtPop3: {
    fontSize: "6vw",
    color: "white",
    [theme.breakpoints.down("md")]: {
      fontSize: "98px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18vw",
    },
  },
  txtPop4: {
    fontSize: "6vw",
    color: "white",

    [theme.breakpoints.down("md")]: {
      fontSize: "98px",
    },
  },
  phonefield: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
        borderWidth: "2px",
      },
    },
  },
}));

function C2CDialog(props) {
  const classes = useStyles();
  const { search } = useLocation();
  const { onClose, open } = props;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checked, setChecked] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isOpenCallingDialog, setIsOpenCallingDialog] = useState(false);
  const [maxWidth] = React.useState("md");
  const [isOpenPrivacyDialog, setIsOpenPrivacyDialog] = useState(false);

  const handleClose = () => {
    setChecked(false);
    setPhoneNumber("");
    setButtonClicked(false);
    onClose();
  };

  const handleCloseCallingDialog = () => {
    setIsOpenCallingDialog(false);
    setButtonClicked(false);
    handleClose();
  };

  const handleClosePrivacyDialog = () => {
    setIsOpenPrivacyDialog(false);
  };

  const handleCheckboxChange = (event) => {
    setChecked(!checked);
  };

  const handleTextFieldChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleClickOpen = () => {
    setIsOpenCallingDialog(true);
    setTimeout(() => {
      onClose();
    }, 500);
  };
  const handleClicPrivacy = () => {
    setIsOpenPrivacyDialog(true);
  };

  return (
    <>
      <CallingDialog
        open={isOpenCallingDialog}
        onClose={handleCloseCallingDialog}
      />
      <PrivacyDialog
        open={isOpenPrivacyDialog}
        onClose={handleClosePrivacyDialog}
      />
      <Dialog
        maxWidth={maxWidth}
        className={classes.dialog}
        onClose={handleClose}
        open={open}
        backgroundColor="red"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
            /*color: (theme) => theme.palette.grey[500],*/
          }}
        >
          <CloseIcon sx={{ fontSize: "2.5rem" }} />
        </IconButton>
        <DialogTitle>
          <Typography
            sx={{
              textAlign: "center",
              marginTop: "2rem",
              padding: { xs: "none", sm: "0rem 2rem", md: "0rem 12rem" },
            }}
            variant="h3"
          >
            <span
              className={classes.txtPop1}
              style={{ display: "block", padding: "0rem 0rem" }}
            >
              ¿Quieres contratar?
            </span>
            <span
              className={classes.txtPop2}
              style={{ display: "block", padding: "0.5rem 0rem" }}
            >
              Te llamamos ahora
            </span>
            <span
              className={classes.txtPop3}
              style={{
                display: "block",
                lineHeight: "0.8em",
                textTransform: "uppercase",
              }}
            >
              Gratis
            </span>
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              marginTop: "0rem",
              padding: { xs: "none", sm: "0rem 2rem", md: "0rem 12rem" },
              fontSize: { xs: "0.8rem", sm: "1rem" },
            }}
            style={{ color: "white", fontWeight: "300" }}
          >
            Nuestro equipo de asesores te atenderá de lunes a viernes de 09:00 a
            21:00
          </Typography>
        </DialogTitle>
        {/* {/* <Divider />/ */}
        <Box
          sx={{
            margin: { xs: "0rem 0rem", sm: "0px" },
            padding: { xs: "0rem 1rem", md: "0rem 12rem" },
            textAlign: "center",
          }}
        >
          <TextField
            classes={{ root: classes.phonefield }}
            inputProps={{ style: { fontSize: 25, color: "white" } }}
            onChange={handleTextFieldChange}
            sx={{ width: "100%", marginTop: "0rem" }}
            label={
              <Typography
                sx={{ marginTop: "6px", color: "white" }}
                variant="body2"
              >
                Tu teléfono
              </Typography>
            }
            variant="outlined"
          />
          <FormControlLabel
            sx={{
              marginTop: "1rem",
              marginBottom: "4px",
              textAlign: "left",
              alignItems: "start",
            }}
            control={
              <ColoredCheckbox
                onChange={handleCheckboxChange}
                labelStyle={{ color: "white" }}
                iconStyle={{ fill: "white" }}
              />
            }
            label={
              <Typography
                color="white"
                variant="subtitle2"
                sx={{ fontSize: { xs: "10px", md: "14px" } }}
              >
                He leído y acepto la{" "}
                <LinkMui
                  onClick={() => {
                    handleClicPrivacy();
                  }}
                  underline="none"
                  sx={{ textDecoration: "none", color: "white" }}
                >
                  política de privacidad
                </LinkMui>{" "}
                y consiento el tratamiento de mis datos personales para todas
                las finalidades contenidas en la misma
              </Typography>
            }
          />
          {!checked && buttonClicked && (
            <Typography variant="subtitle2" color="red">
              Debes aceptar la política de privacidad
            </Typography>
          )}
          {checked && buttonClicked && !isPhoneValid(phoneNumber) && (
            <Typography variant="subtitle2" color="red">
              El número de teléfono no es válido
            </Typography>
          )}
          <Button
            className={classes.button}
            onClick={() => {
              setButtonClicked(true);
              if (checked && isPhoneValid(phoneNumber)) {
                launchC2C(phoneNumber);
                handleClickOpen();
              }
            }}
            sx={{
              background: "#FF8200 0% 0% no-repeat padding-box",
              borderRadius: "5px",
              marginTop: "auto",
              height: "40px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              color="white"
              variant="body3"
              sx={{ textTransform: "initial", fontSize: "18px" }}
            >
              Llámame gratis
            </Typography>
          </Button>
        </Box>
      </Dialog>
    </>
  );
}

export default C2CDialog;
